import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { colors, fonts } from '../styles/variables';

export default function StockTicker(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStockData = async () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
          'x-rapidapi-key': process.env.GATSBY_RAPIDAPI_KEY,
        },
      };

      try {
        const response = await axios.get(
          'https://apidojo-yahoo-finance-v1.p.rapidapi.com/market/v2/get-quotes?symbols=ESCA&region=US',
          options
        );
        setData(response.data.quoteResponse.result[0]);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchStockData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const symbol = data?.symbol;
  const price = data;

  if (!symbol || !price) {
    return <div>For stock information, please visit Nasdaq</div>;
  }

  const percentChange = price?.regularMarketChangePercent;
  const change = price?.regularMarketChange;
  const marketPrice = price?.regularMarketPrice;
  const time = price?.regularMarketTime;

  const updated = `UPDATED: ${moment
    .tz(new Date(time * 1000), 'America/New_York')
    .format('MMM DD')}, ${moment
      .tz(new Date(time * 1000), 'America/New_York')
      .format('h:mm a z')}`;

  return (
    <Div change={change}>
      <span onClick={props.nasdaq}>
        <span id="exchange">NASDAQ:</span>
        &nbsp;
        <span id="symbol">{symbol}</span>
        &nbsp;&nbsp;
        <span id="price">${marketPrice?.toFixed(2)}</span>
        &nbsp;&nbsp;
        <span id="change">{change?.toFixed(2)}</span>
        &nbsp;
        <span id="percent">{`(${percentChange?.toFixed(1)}%)`}</span>
        &nbsp;&nbsp;
        <span id="updated">
          <i>{updated}</i>
        </span>
      </span>
    </Div>
  );
}

const Div = styled.div(props => {
  return css`
    font-size: 8px;
    font-family: ${fonts.primary};
    span {
      cursor: pointer;
    }
    #exchange {
      color: ${colors.primaryLight};
    }
    #symbol,
    #price {
      color: ${colors.white};
    }
    #change,
    #percent {
      color: ${props?.change?.raw > 0
      ? `${colors.green}`
      : props?.change?.raw < 0
        ? `${colors.red}`
        : `${colors.primaryLight}`};
    }
    #updated {
      display: none;
      font-size: 5px;
      color: ${colors.white};
      @media (min-width: 250px) {
        display: initial;
      }
      @media (min-width: 480px) {
        font-size: 10px;
      }
    }
    @media (min-width: 480px) {
      font-size: 16px;
    }
  `;
});
